import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CSRFService } from 'app/core/auth/csrf.service';
import { StateStorageService } from 'app/core/auth/state-storage.service';
import { LoginService } from 'app/core/login/login.service';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { SaveMainStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { ExecutingService } from 'app/service/executing.service';
import { UserService } from 'app/service/user.service';
import { AppState } from 'app/store/app.state';
import { Observable, defer } from 'rxjs';
import { finalize, retryWhen, take, tap } from 'rxjs/operators';
import { AutoLogoutTime, Constant } from './constants';
@Injectable()
export class AccessInterceptor implements HttpInterceptor {
  /**
   * ERROR_DETAIL_TO_STOP_RETRY
   */
  private ERROR_DETAIL_TO_STOP_RETRY = [
    Constant.ERROR_INVALID_DATA_TIMETABLE_EXCEL,
    Constant.ERROR_MULTIPLE_TIME_FORMATS_VALUE_EXCEL,
    Constant.ERROR_NO_DATA_TIMETABLE_IN_EXCEL,
    Constant.ERROR_NO_TEMPLATE,
    Constant.ERROR_NO_AREA_TIMETABLE_OF_TEMPLATE,
    Constant.ERROR_HEADER_IS_EMPTY,
    Constant.ERROR_LIMIT_RECORD,
    Constant.ERROR_EXISTS_NAME,
    Constant.ERROR_RECORD_NOT_EXISTS,
    Constant.ERROR_EXISTS_NAME_TIMETABLE,
    Constant.ERROR_EXISTS_NAME_USER,
    Constant.ERROR_EXISTS_SUFFIX_NO,
    Constant.ERROR_INDEX_WORD_NOT_EXISTS,
    Constant.ERROR_LIMIT_SIZE,
    Constant.ERROR_LIMIT_HEIGHT,
    Constant.ERROR_LIMIT_WIDTH,
    Constant.DUPLICATE_TIMETABLE_ID,
    Constant.DUPLICATE_NO,
    Constant.HEADERS_NOT_MAP,
    Constant.ERROR_FONTS,
    Constant.ERROR_EMPTY_CITY,
    Constant.ERROR_EMPTY_LATITUDE,
    Constant.ERROR_EMPTY_LONGITUDE,
    Constant.ERROR_FORMAT_LATITUDE,
    Constant.ERROR_FORMAT_LONGITUDE,
    Constant.ERROR_NO_START_CODE,
    Constant.ERROR_FORMAT_HEADER,
    Constant.ERROR_START_CODE,
    Constant.ERROR_INVALID_DATA_FORMAT_TIMETABLE_EXCEL,
    Constant.ERROR_AREA_GROUP_NAME_DOES_NOT_EXIST
  ];

  private readonly MAX_RETRY_COUNT = 10;
  /**
   * common object
   */
  commonObject: Common;
  constructor(
    public csrfService: CSRFService,
    private executingService: ExecutingService,
    public readonly store: Store<AppState>,
    public commonService: CommonService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private userService: UserService,
    private stateStorageService: StateStorageService,
    private router: Router
  ) {
    this.store
      .select(state => state)
      .subscribe((componentState: any) => {
        this.commonObject = componentState?.mainState?.stateOfComponent?.common ?? new Common();
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let csrfToken = this.csrfService.getCSRF();
    let timeout = -1;
    let setting = this.commonObject.setting;
    if (setting) {
      switch (setting.autoLogoutTime) {
        case AutoLogoutTime.FIVE_MINUTE:
          timeout = 5 * 60;
          break;
        case AutoLogoutTime.TEN_MINUTE:
          timeout = 10 * 60;
          break;
        case AutoLogoutTime.THIRSTY_MINUTE:
          timeout = 30 * 60;
          break;
        case AutoLogoutTime.ONE_HOUR:
          timeout = 60 * 60;
          break;
        case AutoLogoutTime.NEVER:
        default:
          break;
      }
    }
    request =
      !request.url.includes('auth/login') && !request.url.includes('ticket-portal-api.lecip-r05-dev.net/v1')
        ? request.clone({
            setHeaders: {
              'X-XSRF-TOKEN': csrfToken,
              [Constant.TENANT_HEADER]: this.commonObject.tenantName,
              sessionTimeout: timeout + '',
              [Constant.USER_ID]: this.commonObject?.userIdString ?? '',
              Authorization: `${sessionStorage.getItem('access_token')}`,
              refresh_token: `${sessionStorage.getItem('refresh_token')}`
            }
          })
        : request.url.includes('auth/login')
        ? request.clone({ setHeaders: { 'X-XSRF-TOKEN': csrfToken, sessionTimeout: timeout + '' } })
        : request.clone({
            setHeaders: {
              'X-XSRF-TOKEN': csrfToken,
              [Constant.TENANT_HEADER]: this.commonObject.tenantName,
              sessionTimeout: timeout + '',
              [Constant.USER_ID]: this.commonObject?.userIdString ?? '',
              Authorization: `${sessionStorage.getItem('access_token')}`
            }
          });
    let retryCount = 0;
    const handleHttp = next.handle(request).pipe(
      retryWhen(errors =>
        errors.pipe(
          tap(errorStatus => {
            retryCount++;
            this.executingService.executed();
            if (!request.url.includes('auth/login') && errorStatus.status != Constant.NETWORK_ERROR_CODE) {
              const functionName = request.url.substring(request.url.lastIndexOf('/') + 1);
              this.commonService.writeErrorLog(JSON.stringify(errorStatus.error), this.commonObject.moduleName, functionName).toPromise();
            }
            if (
              (this.ERROR_DETAIL_TO_STOP_RETRY.includes(errorStatus.error?.detail) && errorStatus.status == 500) ||
              (Constant.SKIP_RETRY_URLS.some(url => request.url.includes(url)) && errorStatus.status == 500) ||
              (!errorStatus.status?.toString().startsWith('5') && errorStatus.status != 408) ||
              errorStatus.error?.detail.includes(Constant.ERROR_DUPLICATE_INDEXWORD)
            ) {
              throw errorStatus;
            } else if (retryCount >= this.MAX_RETRY_COUNT - 1) {
              this.dialogService.showDialog(
                DialogMessageComponent,
                {
                  data: {
                    title: this.translateService.instant('index-word-editor.msg.title-error'),
                    text:
                      !request.url.includes('auth/login') && !request.url.includes('auth/logout')
                        ? this.translateService.instant('main.error-log-out')
                        : this.translateService.instant('index-word-editor.msg.common-error')
                  }
                },
                result => {
                  this.userService.updateUserLogout(this.commonObject?.userIdString).subscribe();
                  sessionStorage.clear();
                  this.store.dispatch(
                    new SaveMainStateAction({
                      common: undefined
                    })
                  );
                  this.stateStorageService.storeUrl(this.router.routerState.snapshot.url);
                  this.loginService.logout();

                  window.location.reload();
                  return;
                }
              );
            }
            console.log('Retrying...' + errorStatus.status);
          }),
          take(this.MAX_RETRY_COUNT - 1)
          // delay(1000),
          // take(1)
        )
      ),
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.url.includes('auth/login')) {
            sessionStorage.setItem('access_token', event.body['access_token']);
            sessionStorage.setItem('refresh_token', event.body['refresh_token']);
          } else if (event.headers.get('access_token')) {
            sessionStorage.setItem('access_token', event.headers.get('access_token'));
          }
        }
      }),
      finalize(() => {
        if (!Constant.SKIP_WAITING_URLS.some(url => request.url.includes(url))) {
          this.executingService.executed();
        }
      })
    );
    const httpEvent = defer(() => {
      if (!Constant.SKIP_WAITING_URLS.some(url => request.url.includes(url))) {
        this.executingService.executing();
      }
      return handleHttp;
    });
    return httpEvent;
  }
}
